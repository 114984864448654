import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {useNavigate} from "react-router";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';

const mainListItems = [
    {
        text: "Početna",
        icon: <HomeRoundedIcon/>,
        route: "../klijent/početna",
    },
    {
        text: "Moji termini",
        icon: <CalendarMonthIcon/>,
        route: "../klijent/termini",
    },
    {
        text: "Zakazivanje",
        icon: <ScheduleIcon/>,
        route: "/zakazivanje",
    },
];

export default function MenuContent() {
    let selectedIndex = localStorage.getItem("index");

    const navigate = useNavigate();

    return (
        <Stack sx={{flexGrow: 1, p: 1, justifyContent: "space-between"}}>
            <List dense>
                {mainListItems.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{display: "block"}}>
                        <ListItemButton
                            onClick={() => {
                                localStorage.setItem("index", index);
                                navigate(item.route);
                            }}
                            selected={index == selectedIndex}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
}
