import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import KlijentLayout from "../layout/KlijentLayout";

const KlijentRoute = () => {
    const role = localStorage.getItem("role");

    if (role != 3) {
        localStorage.clear();
        return <Navigate to="/login"/>;
    }

    return (
        <KlijentLayout>
            <Outlet/>
        </KlijentLayout>
    );
};

export default KlijentRoute;