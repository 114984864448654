import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/website/HomePage";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import WebsiteRoute from "./routes/WebsiteRoute";
import './assets/css/index.css';
import PomocIPitanjaPage from "./pages/website/prava/PomocIPitanjaPage";
import UsloviKoriscenjaPage from "./pages/website/prava/UsloviKoriscenjaPage";
import PolitikaPrivatnostiPage from "./pages/website/prava/PolitikaPrivatnostiPage";
import KolaciciPage from "./pages/website/prava/KolaciciPage";
import PlacanjeKarticamaPage from "./pages/website/prava/PlacanjeKarticamaPage";
import ReklamacijeIPovracajRobePage from "./pages/website/prava/ReklamacijeIPovracajRobePage";
import PravoNaOdustajanjePage from "./pages/website/prava/PravoNaOdustajanjePage";
import PovracajSredstavaPage from "./pages/website/prava/PovracajSredstavaPage";
import CarWashCenovnikPage from "./pages/website/cenovnik/CarWashCenovnikPage";
import DetailingCenovnik from "./pages/website/cenovnik/DetailingCenovnik";
import ONamaPage from "./pages/website/ONamaPage";
import KontaktPage from "./pages/website/KontaktPage";
import AdministratorRoute from "./routes/AdministratorRoute";
import LoginPage from "./pages/LoginPage";
import AdminDashboardPage from "./pages/administrator/AdminDashboardPage";
import AdminPartneriPage from "./pages/administrator/AdminPartneriPage";
import AdminTerminiPage from "./pages/administrator/AdminTerminiPage";
import AdminUslugePage from "./pages/administrator/AdminUslugePage";
import AdminKlijentiPage from "./pages/administrator/AdminKlijentiPage";
import AdminRadniciPage from "./pages/administrator/AdminRadniciPage";
import ErrorPage from "./pages/error/ErrorPage";
import PartnerInfoPage from "./pages/administrator/Informacije/PartnerInfoPage";
import TerminInfoPage from "./pages/administrator/Informacije/TerminInfoPage";
import UslugaInfoPage from "./pages/administrator/Informacije/UslugaInfoPage";
import KlijentInfoPage from "./pages/administrator/Informacije/KlijentInfoPage";
import RadnikInfoPage from "./pages/administrator/Informacije/RadnikInfoPage";
import GalerijaPage from "./pages/website/galerija/GalerijaPage";
import AdminGalerijaPage from "./pages/administrator/AdminGalerijaPage";
import EclecticWashPage from "./pages/website/usluge/EclecticWashPage";
import StandardWashPage from "./pages/website/usluge/StandardWashPage";
import QuickWashPage from "./pages/website/usluge/QuickWashPage";
import CorporateWashPage from "./pages/website/usluge/CorporateWashPage";
import OdrzavanjeKeramickeZastitePage from "./pages/website/usluge/OdrzavanjeKeramickeZastitePage";
import NanosenjeZastitnogPremazaPage from "./pages/website/usluge/NanosenjeZastitnogPremazaPage";
import NanosenjeVoskaIliSilerPage from "./pages/website/usluge/NanosenjeVoskaIliSilerPage";
import PoliranjeFarovaPage from "./pages/website/usluge/PoliranjeFarovaPage";
import DetailingMotornogProstoraPage from "./pages/website/usluge/DetailingMotornogProstoraPage";
import DezinfekcijaOzonomPage from "./pages/website/usluge/DezinfekcijaOzonomPage";
import UklanjanjeFlekaPage from "./pages/website/usluge/UklanjanjeFlekaPage";
import CiscenjeKoznihElemenataPage from "./pages/website/usluge/CiscenjeKoznihElemenataPage";
import UklanjanjeDlakaOdLjubimacaPage from "./pages/website/usluge/UklanjanjeDlakaOdLjubimacaPage";
import AdminPromoKodoviPage from "./pages/administrator/AdminPromoKodoviPage";
import RadnikRoute from "./routes/RadnikRoute";
import PotvrdaPlacanjaPage from "./pages/website/PotvrdaPlacanjaPage";
import AdminNeradniDaniPage from "./pages/administrator/AdminNeradniDaniPage";
import RadnikDashboardPage from "./pages/radnici/RadnikDashboardPage";
import RadnikTerminiPage from "./pages/radnici/RadnikTerminiPage";
import RadnikNoviTerminPage from "./pages/radnici/RadnikNoviTerminPage";
import RadnikTerminInfoPage from "./pages/radnici/RadnikTerminInfoPage";
import AdminMusterijePage from "./pages/administrator/AdminMusterijePage";
import ShopPage from "./pages/website/ShopPage";
import AdminNewsLetterPage from "./pages/administrator/AdminNewsLetterPage";
import UnsubscribePage from "./pages/website/UnsubscribePage";
import KlijentPocetnaPage from "./pages/klijenti/KlijentPocetnaPage";
import KlijentRoute from "./routes/KlijentRoute";

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginPage/>}/>

                <Route path="/*" element={<WebsiteRoute/>}>
                    <Route path="" index={true} element={<HomePage/>}/>
                    <Route path="prodavnica" element={<ShopPage/>}/>

                    <Route path="o-nama" element={<ONamaPage/>}/>
                    <Route path="kontakt" element={<KontaktPage/>}/>

                    <Route path="car-wash-cenovnik" element={<CarWashCenovnikPage/>}/>
                    <Route path="detailing-cenovnik" element={<DetailingCenovnik/>}/>

                    <Route path="galerija" element={<GalerijaPage/>}/>
                    
                    <Route path="potvrda-placanja/:code/:uuid" element={<PotvrdaPlacanjaPage/>}/>

                    <Route path="uslovi-korišćenja-i-prodaje" element={<UsloviKoriscenjaPage/>}/>
                    <Route path="politika-privatnosti" element={<PolitikaPrivatnostiPage/>}/>
                    <Route path="kolačići" element={<KolaciciPage/>}/>
                    <Route path="plaćanje-karticama" element={<PlacanjeKarticamaPage/>}/>
                    <Route path="reklamacije-i-povraćaj-robe" element={<ReklamacijeIPovracajRobePage/>}/>
                    <Route path="pravo-na-odustajanje" element={<PravoNaOdustajanjePage/>}/>
                    <Route path="povraćaj-sredstava" element={<PovracajSredstavaPage/>}/>
                    <Route path="pomoć-i-najčešća-pitanja" element={<PomocIPitanjaPage/>}/>

                    <Route path="corporate-wash" element={<CorporateWashPage/>}/>
                    <Route path="quick-wash" element={<QuickWashPage/>}/>
                    <Route path="standard-wash" element={<StandardWashPage/>}/>
                    <Route path="eclectic-wash" element={<EclecticWashPage/>}/>
                    <Route path="keramicke-zastite" element={<OdrzavanjeKeramickeZastitePage/>}/>
                    <Route path="zastitni-premaz" element={<NanosenjeZastitnogPremazaPage/>}/>
                    <Route path="nanosenje-voska" element={<NanosenjeVoskaIliSilerPage/>}/>
                    <Route path="poliranje-farova" element={<PoliranjeFarovaPage/>}/>
                    <Route path="detailing-motornog-prostora" element={<DetailingMotornogProstoraPage/>}/>
                    <Route path="dezinfekcija" element={<DezinfekcijaOzonomPage/>}/>
                    <Route path="uklanjanje-fleka" element={<UklanjanjeFlekaPage/>}/>
                    <Route path="ciscenje-koznih-sedista" element={<CiscenjeKoznihElemenataPage/>}/>
                    <Route path="uklanjanje-dlaka" element={<UklanjanjeDlakaOdLjubimacaPage/>}/>

                    <Route path="unsubscribe/:email" element={<UnsubscribePage/>}/>
                    <Route path="*" element={<ErrorPage/>}/>
                </Route>

                <Route path="/administrator/*" element={<AdministratorRoute/>}>
                    <Route path="početna" element={<AdminDashboardPage/>}/>
                    <Route path="partneri" element={<AdminPartneriPage/>}/>
                    <Route path="termini" element={<AdminTerminiPage/>}/>
                    <Route path="usluge" element={<AdminUslugePage/>}/>

                    <Route path="mušterije" element={<AdminMusterijePage/>}/>

                    <Route path="klijenti" element={<AdminKlijentiPage/>}/>
                    <Route path="radnici" element={<AdminRadniciPage/>}/>
                    <Route path="galerija" element={<AdminGalerijaPage/>}/>
                    <Route path="promo-kodovi" element={<AdminPromoKodoviPage/>}/>
                    <Route path="neradni-dani" element={<AdminNeradniDaniPage/>}/>
                    <Route path="novosti" element={<AdminNewsLetterPage/>}/>

                    <Route path="partner/:id" element={<PartnerInfoPage/>}/>
                    <Route path="termin/:id" element={<TerminInfoPage/>}/>
                    <Route path="usluga/:id" element={<UslugaInfoPage/>}/>
                    <Route path="klijent/:id" element={<KlijentInfoPage/>}/>
                    <Route path="radnik/:id" element={<RadnikInfoPage/>}/>

                    <Route path="*" element={<ErrorPage/>}/>
                </Route>

                <Route path="/radnik/*" element={<RadnikRoute/>}>
                    <Route path="početna" element={<RadnikDashboardPage/>}/>

                    <Route path="termini" element={<RadnikTerminiPage/>}/>
                    <Route path="novi-termin" element={<RadnikNoviTerminPage/>}/>
                    <Route path="termin/:id" element={<RadnikTerminInfoPage/>}/>

                    <Route path="*" element={<ErrorPage/>}/>
                </Route>

                <Route path="/klijent/*" element={<KlijentRoute/>}>
                    <Route path="početna" element={<KlijentPocetnaPage/>}/>

                    <Route path="*" element={<ErrorPage/>}/>
                </Route>

                <Route path="*" element={<ErrorPage/>}/>
            </Routes>
        </BrowserRouter>
    )
}

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AppRoutes></AppRoutes>
        </QueryClientProvider>
    </React.StrictMode>
);
